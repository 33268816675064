<template>
  <v-sheet
    class="overflow-hidden search-item"
    :title="item.name + '\n' + item.address"
    v-bind="$attrs"
    v-on="$listeners"
    tile
  >
    <div class="d-flex pa-2">
      <div class="flex-grow-1 pr-2">
        <div class="full-width">
          <div class="full-width text-subtitle-2">
            <div style="word-break: break-word" class="full-width">
              {{ item.address || item.name }}
            </div>
          </div>
          <div v-if="item.place" class="text-subtitle-1">
            <template> {{ item.place.name }}</template>
          </div>
          <div class="ml-n1">
            <Rating :value="item.rating"></Rating>
          </div>
          <div class="text-caption">
            <StatusTypeText :value="!item.isClosed" />
          </div>
        </div>
      </div>

      <div class="flex-grow-0">
        <v-img
          :src="item.background || '/images/default-picture-2.png'"
          tile
          width="64"
          height="64"
        ></v-img>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import Rating from "@components/rating.vue";
import StatusTypeText from "@/views/StatusTypeText.vue";
export default {
  components: { StatusTypeText, Rating },
  props: { item: { requred: true } },
};
</script>

<style>
.search-item:hover {
  background-color: rgba(60, 64, 67, 0.04);
}
.search-item {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
</style>
